<template>
    <div class="content">
        <el-form ref="form" :model="form" label-width="220px">
            <el-form-item label="积分系统开关">
                <el-switch v-model="form.is_open_integral"></el-switch>
            </el-form-item>
            <el-form-item label="积分过期设置" class="formItem_Two">
                <el-radio :label="1" v-model="form.integral.status">永不过期</el-radio>
                <el-radio :label="2" v-model="form.integral.status">
                    每 年
                    <el-select :disabled="form.integral.status != 2" v-model="form.integral.expire.month" placeholder="月"
                        size="mini">
                        <el-option v-for="item in options_month" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>月
                    <el-select :disabled="form.integral.status != 2" v-model="form.integral.expire.day" placeholder="日"
                        size="mini">
                        <el-option v-for="item in options_day" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>日
                    <el-select :disabled="form.integral.status != 2" v-model="form.integral.expire.hour" placeholder="时"
                        size="mini">
                        <el-option v-for="item in options_hour" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>点过期
                </el-radio>
            </el-form-item>
            <el-form-item label="其他设置">
                <el-checkbox v-model="form.other"> 会员积分不足，不允许退款</el-checkbox>
            </el-form-item>
            <el-form-item label="客户自助充值设置" class="formItem_Four">
                <div>
                    <el-radio :label="1" v-model="form.self_recharge.status">
                        允许自由充值最低
                        <el-input :disabled="form.self_recharge.status != 1" v-model="form.self_recharge.min_price"
                            size="mini"></el-input>起充
                    </el-radio>
                </div>
                <div>
                    <el-radio :label="2" v-model="form.self_recharge.status">不允许自由充值
                        <div style="margin:15px 0  0 25px ;">
                            自由充值金额模板
                            <el-input :disabled="form.self_recharge.status != 2"
                                v-model="form.self_recharge.price_template[item]" v-for="item in inputIndex" :key="item"
                                size="mini"></el-input>
                            使用说明
                        </div>
                    </el-radio>
                </div>
            </el-form-item>
            <el-form-item label="会员卡支付扣款顺序">
                <div>
                    <el-radio :label="1" v-model="form.deduction.status">优先扣除本金，再扣除赠送 </el-radio>
                </div>
                <div>
                    <el-radio :label="2" v-model="form.deduction.status">优先扣除赠送，再扣除本金</el-radio>
                </div>
                <div>
                    <el-radio :label="3" v-model="form.deduction.status">按比例扣除，本金：赠送
                        <el-input :disabled="form.deduction.status != 3" v-model="form.deduction.scale.principal"
                            style="width: 60px;" size="mini">
                        </el-input>
                        <span style="margin: 0 8px;"> : </span>
                        <el-input :disabled="form.deduction.status != 3" v-model="form.deduction.scale.give"
                            style="width: 60px;" size="mini">
                        </el-input>
                    </el-radio>
                </div>
            </el-form-item>
            <el-form-item label="会员卡支付购买商品限制">
                <div>
                    <el-radio :label="1" v-model="form.pay_limit">仅支持本金购买 </el-radio>
                </div>
                <div>
                    <el-radio :label="2" v-model="form.pay_limit">支持本金+ 赠送</el-radio>
                </div>
            </el-form-item>
            <el-form-item label="新建会员是是否允许选择等级">
                <div>
                    <el-radio :label="0" v-model="form.is_new_level">是</el-radio>
                </div>
                <div>
                    <el-radio :label="1" v-model="form.is_new_level">否
                        <span style="margin-left: 20px;">
                            请设置新建会员默认等级
                            <el-input-number size="mini" :disabled="form.is_new_level == 0" style="width: 100px;"
                                v-model="formLevel" controls-position="right" :min="1" :max="10">
                            </el-input-number>
                            <!-- @change="handleChange" -->
                        </span>
                    </el-radio>
                </div>
            </el-form-item>
            <el-form-item label="关注微信公众号便成为酒店会员">
                <el-switch v-model="form.is_follow"></el-switch>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="onSubmit" size="small">保存</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
export default {
    props: {
        type_id: {
            type: Number
        }
    },
    created() {
        this.getMemberDetail()
    },
    mounted() {
        // this.getMemberDetail()
    },
    data() {
        return {
            options_month: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            options_day: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
            options_hour: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
            inputIndex: [0, 1, 2, 3, 4, 5],
            form: {
                is_open_integral: null, // 积分系统开关 1 表示开启 0 表示关闭
                integral: { // 积分过期设置 
                    status: null, // 1 表示永不过期 2 过期
                    expire: { // 过期时间设置   status=2 必传
                        month: null, // 某月
                        day: null,   // 某日
                        hour: null  // 某时
                    }
                },
                other: null,// 其他设置 1选中 0 未选中
                self_recharge: { // 自助充值设置  
                    status: null, // 1 允许自由充值 2 自定义充值
                    min_price: null, //  表示最低充值金额 status = 1必传
                    price_template: ['', '', '', '', '', '',] // 表示充值模板 status=2 必传 数组
                },
                deduction: {// 支付扣款顺序
                    status: null,// 1优先扣除本金，再扣除赠送 2 优先扣除赠送，再扣除本金，3 按比例扣除
                    scale: { // status =3 必传
                        principal: null, // 本金
                        give: null // 赠送
                    },
                },
                pay_limit: null, // 1 仅支持本金购买  2 支持本金+赠送购买
                is_new_level: null, // 0 表示是 非0 表示具体等级
                is_follow: null // 关注公众号成为酒店会员 1开启 0 关闭
            },
            formLevel: 1,
        }
    },
    methods: {
        // 修改基础配置
        onSubmit() {
            let data = {
                id: this.type_id,
                is_open_integral: this.form.is_open_integral ? 1 : 0, // 积分系统开关 1 表示开启 0 表示关闭
                integral: this.form.integral,
                other: this.form.other ? 1 : 0,// 其他设置 1选中 0 未选中
                self_recharge: this.form.self_recharge,
                deduction: this.form.deduction,
                pay_limit: this.form.pay_limit, // 1 仅支持本金购买  2 支持本金+赠送购买
                is_new_level: this.form.is_new_level == 0 ? 0 : this.formLevel, // 0 表示是 非0 表示具体等级
                is_follow: this.form.is_follow ? 1 : 0 // 关注公众号成为酒店会员 1开启 0 关闭
            }
            console.log('保存基础配置data:', data)
            this.my_request('admin/member/config/basic', data, 'PUT').then(res => {
                console.log('保存基础配置data:', res)
                let { code, message } = res.data
                if (200 == code) {
                    this.$message.success('保存成功')
                    // this.$confirm('保存成功, 是否返回会员类别页面?', '提示', {
                    //     confirmButtonText: '确定',
                    //     cancelButtonText: '取消',
                    //     // type: 'warning'
                    // }).then(() => {
                    //     setTimeout(() => {
                    //         this.$router.push(`/vipmanage/MemberCategory`)
                    //     }, 500);
                    // }).catch(() => {
                    // });
                } else {
                    this.$message.error('保存失败')
                }
            })
        },
        getMemberDetail() {
            let data = {
                type_id: this.type_id
            }
            // console.log('获取会员配置详情data:', data)
            this.my_request('admin/member/config', data, 'GET').then(res => {
                console.log('获取会员配置详情结果:', res)
                let { code, data } = res.data
                if (200 == code) {
                    this.form = data
                    this.form.is_open_integral = data.is_open_integral == 1 ? true : false
                    this.form.other = data.other == 1 ? true : false
                    this.form.is_follow = data.is_follow == 1 ? true : false
                    this.formLevel = data.is_new_level == 0 ? 1 : data.is_new_level
                    let deduction = {
                        status: null,
                        scale: {
                            principal: null,
                            give: null
                        },
                    }
                    let integral = {
                        status: null,
                        expire: {
                            month: null,
                            day: null,
                            hour: null
                        }
                    }
                    let self_recharge = {
                        status: null,
                        min_price: null,
                        price_template: ['', '', '', '', '', '',]
                    }
                    this.form.integral = this.form.integral.length == 0 ? integral : this.form.integral
                    this.form.deduction = this.form.deduction.length == 0 ? deduction : this.form.deduction
                    this.form.self_recharge = this.form.self_recharge.length == 0 ? self_recharge : this.form.self_recharge
                    // console.log('form', this.form)
                }
            })

        }
    }
}
</script>
<style scoped lang="scss">
.formItem_Two {
    .el-select {
        width: 60px;
        margin: 0 5px;
    }
}

.formItem_Four {
    .el-input {
        width: 60px;
        margin: 0 5px;
    }
}

.el-form-item {
    margin-bottom: 8px;
}
</style>