<template>
    <div>
        <div class="conten">
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <!-- @tab-click="handleClick" -->
                <el-tab-pane label="基础配置" name="Basic">
                    <Basic :type_id="Number(configMemberId)"></Basic>
                </el-tab-pane>
                <el-tab-pane label="会员等级" name="Grade">
                    <Grade :type_id="Number(configMemberId)" :activeName="activeName"></Grade>
                </el-tab-pane>
                <el-tab-pane label="充值赠送" name="RechargeGift">
                    <RechargeGift :type_id="Number(configMemberId)" :activeName="activeName"></RechargeGift>
                </el-tab-pane>
                <!-- <el-tab-pane label="会员价格" name="price">
                    <Price></Price>
                </el-tab-pane> -->
                <el-tab-pane label="积分获取" name="GetPoints">
                    <GetPoints :type_id="Number(configMemberId)" :activeName="activeName"></GetPoints>
                </el-tab-pane>
            </el-tabs>
        </div>

    </div>
</template>
<script>
import Basic from '../../components/MemberConfig_tabs/Basic/Basic.vue'
import Grade from '../../components/MemberConfig_tabs/Grade/Grade.vue'
import GetPoints from '../../components/MemberConfig_tabs/GetPoints/GetPoints.vue'
// import Price from '../../components/MemberConfig_tabs/Price/Price.vue'
import RechargeGift from '../../components/MemberConfig_tabs/RechargeGift/RechargeGift.vue'

import { mapState, mapMutations } from 'vuex'
export default {
    components: {
        Basic, Grade, GetPoints, RechargeGift
        // Price,
    },
    computed: {
        ...mapState({
            configDetail: state => state.memberConfig.configDetail
        }),
    },
    data() {
        return {
            activeName: 'Basic',
            num: 1,
            value: '1',
            options: [],
            form: {
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: ''
            },

            channel: [{
                id: 1,
                value: '本地渠道',
            }, {
                id: 2,
                value: '微信公众号',
            }],
            tableData_getPoints: [],
            tableData_RechargeGift: [],
            tableData_gradeList: [],

            configMemberId: null,//当前操作的会员类别id
        }
    },
    created() {
        this.configMemberId = this.$route.query.id
        this.getMemberDetail()

    },
    mounted() { },

    methods: {
        // ...mapMutations('memberConfig', ['getConfigDetail']),
        ...mapMutations({
            getConfigDetail: 'getConfigDetail'
        }),
        onSubmit() {
            console.log('submit!');
        },
        handleClick() {
            console.log(this.activeName, 'activeName');
        },
        getMemberDetail() {
            let data = {
                type_id: this.configMemberId
            }
            this.my_request('admin/member/config', data, 'GET').then(res => {
                // console.log('获取会员配置详情结果:', res)
                let data = res.data.data
                this.$store.commit('getConfigDetail', { data })
            })

        }
    }
}
</script>
<style lang="scss" scoped>
.el-form-item {
    margin-bottom: 8px;
}



.conten {
    width: 100%;

    // height: 100%;
    // background-color: #61AFFF;
    .el-tabs {
        margin-bottom: 0px !important;
    }
}

::v-deep .el-tabs__header.is-top {
    margin-bottom: 5px !important;

}
</style>