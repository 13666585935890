<template>
    <div class="content grade">
        <div class="grade">
            <div class="grade_top">
                提示：开启储值卡功能，则不能开启会员卡降级功能，即储值卡功能和会员卡降级功能不能共存，无论会员卡降级功能还是储值卡降级功能，会员卡余额小于设置的值时，
                <div>便会降级到设置的会员等级</div>
            </div>
            <div class="grade_form">
                <el-form ref="form" :model="form" label-width="250px">
                    <el-form-item label="会员等级升级规则设置">
                        <div>
                            <el-radio :label="1" v-model="form.member_upgradation">会员单次充值，累计积分，累计充值同时满足等级要求时，才会升级</el-radio>
                        </div>
                        <div>
                            <el-radio :label="2" v-model="form.member_upgradation">会员单次充值，累计积分，累计充值任一满足等级要求时，即会升级</el-radio>
                        </div>
                    </el-form-item>
                    <el-form-item label="是否开启会员卡降级">
                        <div>
                            <el-radio :label="1" v-model="form.member_degrade.status">
                                是 余额小于
                                <el-input v-model="form.member_degrade.rule.money" style="width:60px;margin: 0 5px;"
                                    size="mini" :disabled="form.member_degrade.status != 1"></el-input>
                                元时，降级为
                                <el-select v-model="form.member_degrade.rule.grade_id" placeholder="请选择"
                                    :disabled="form.member_degrade.status != 1" style="width: 100px;" size="small">
                                    <el-option v-for="item in options_level" :key="item.id" :label="item.level"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-radio>
                        </div>
                        <div>
                            <el-radio :label="0" v-model="form.member_degrade.status">否</el-radio>
                        </div>
                    </el-form-item>
                    <el-form-item label="是否开启存储卡功能">
                        <div>
                            <el-radio :label="1" v-model="form.store_card.status">是 存储卡金额小于
                                <el-input :disabled="form.store_card.status != 1" v-model="form.store_card.rule.money"
                                    style="width: 60px;margin: 0 5px;" size="mini"></el-input>时，降级为会员卡
                            </el-radio>
                        </div>
                        <div>
                            <el-radio :label="0" v-model="form.store_card.status">否</el-radio>
                        </div>
                    </el-form-item>
                    <el-form-item label="存储卡升级策略">
                        <div>
                            <el-radio :label="1" v-model="form.store_card_upgradation">按充值金额匹配等级 </el-radio>
                        </div>
                        <div>
                            <el-radio :label="2" v-model="form.store_card_upgradation">
                                储值卡降级为会员卡后，用户再次充值商家要求的最低金额后，就可升级到降级之前的储值卡等级
                            </el-radio>
                        </div>
                    </el-form-item>
                    <el-form-item label="是否开启微信公众号内可查看会员权益">
                        <el-switch v-model="form.is_open_wx_view"></el-switch>
                    </el-form-item>
                    <el-form-item label="新建会员是是否允许选择等级">
                        <div>
                            <el-radio :label="1" v-model="form.upgradation_show">累计积分</el-radio>
                            <el-radio :label="2" v-model="form.upgradation_show">累计充值</el-radio>
                            <el-radio :label="3" v-model="form.upgradation_show">单笔充值</el-radio>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit" size="small">保存</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="line"></div>


            <!-- 会员等级表格 -->
            <div class="gradeList">
                <div class="gradeList_top">
                    <el-button size="small" type="primary" @click="openAddDialog">新增会员等级</el-button>
                    <!-- <el-button size="small" type="primary">配置会员权益</el-button> -->
                </div>
                <div class="gradeList_table">
                    <el-table :data="tableData_gradeList" style="width: 100%;height: 340px;" max-height="340">
                        <el-table-column prop="title" align="center" label="名称"> </el-table-column>
                        <el-table-column prop="level" align="center" label="级别"> </el-table-column>
                        <el-table-column prop="single_recharge" align="center" label="单笔充值要求"> </el-table-column>
                        <el-table-column prop="integral" align="center" label="累计积分要求"> </el-table-column>
                        <el-table-column prop="total_recharge" align="center" label="累计充值要求"> </el-table-column>
                        <el-table-column prop="price" align="center" label="卖价"> </el-table-column>
                        <el-table-column prop="is_fixed" align="center" label="是否固定等级">
                            <template slot-scope="scope">
                                {{ scope.row.is_fixed == 1 ? '是' : '否' }}
                            </template>
                        </el-table-column>
                        <!-- <el-table-column prop="expire_time" align="center" label="会员权益">
                            <template slot-scope="scope">
                                <el-button size="mini" type="text">查看</el-button>
                            </template>
                        </el-table-column> -->
                        <el-table-column prop="expire_time" align="center" label="升降级策略">
                            <template slot-scope="scope">
                                <el-button size="mini" type="text" @click="open_strategy_form(scope.row)">查看</el-button>
                            </template>
                        </el-table-column>
                        <el-table-column prop="expire_time" align="center" label="有效期"> </el-table-column>
                        <el-table-column label="操作" width="180" align="right">
                            <template slot-scope="scope">
                                <el-button type="text" size="mini" @click="handleEdit(scope.row)">修改</el-button>
                                <!-- <el-button type="text" size="mini"
                                    @click="handleEdit(scope.$index, scope.row)">设置默认</el-button> -->
                                <el-button size="mini" type="text" style="color: red;"
                                    @click="handleDelete(scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="pagination">
                        <Pagination :data="pageNation_data" @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"></Pagination>
                    </div>
                </div>
            </div>
        </div>
        <!-- 新增/编辑 会员等级对话框 -->
        <el-dialog :title="add_form_title" :visible.sync="dialogVisible" width="700px">
            <div class="dialog_top" v-if="add_form_title == '编辑会员等级'">
                <span>说明:</span>
                <div>
                    <p> 1.非期限会员修改为期限会员，老会员的有效期由无限制变为有限制，有效期自修改日开始计
                        算。期限会员修改为非期限会员，老会员的有效期由有效期有限制变为无限制</p>
                    <p> 2.修改期限会员有效期后，不影响老会员有效期，只对新办会员有效</p>
                    <p>3.修改等级为当前最高等级或最低等级会清除其它升降级策略对应升级或降级数据</p>
                </div>
            </div>
            <el-form ref="add_form" :model="add_form" label-width="120px">
                <el-form-item label="类别">
                    <el-radio-group v-model="add_form.is_term">
                        <el-radio :label="0">非期限会员</el-radio>
                        <el-radio :label="1">期限会员</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="等级名称">
                    <el-input size="small" v-model="add_form.title" style="width: 150px;" placeholder="请输入等级名称"> </el-input>
                </el-form-item>
                <el-form-item label="级别">
                    <el-select v-model="add_form.level_id" placeholder="请选择" size="small" style="width: 120px;">
                        <el-option v-for="item in options_level" :key="item.id" :label="item.level" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- 期限会员 -->
                <el-form-item label="有效期" v-if="add_form.is_term == 1">
                    <el-input size="small" v-model="add_form.validty" style="width: 100px;margin-right: 10px;"> </el-input>
                    <el-select v-model="add_form.unit" placeholder="单位" size="small" style="width: 80px;">
                        <el-option v-for="item in options_unit" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- 期限会员 -->
                <!-- 非期限会员 -->
                <div v-show="add_form.is_term == 0">
                    <el-form-item label="单次充值要求">
                        <el-input v-model="add_form.single_recharge" size="small" style="width: 150px;"> </el-input>
                        <span class="demand">输入"0",代表无要求</span>
                    </el-form-item>
                    <el-form-item label="累计积分要求">
                        <el-input v-model="add_form.integral" size="small" style="width: 150px;"> </el-input>
                        <span class="demand">输入"0",代表无要求</span>
                    </el-form-item>
                    <el-form-item label="累计充值要求">
                        <el-input v-model="add_form.total_recharge" size="small" style="width: 150px;"> </el-input>
                        <span class="demand">输入"0",代表无要求</span>
                    </el-form-item>
                </div>

                <el-form-item label="添加会员权益">
                    <div class="addEquity">
                        <el-select v-model="add_form.equity[index]" v-for="(item, index) in add_form.equity" :key="index"
                            placeholder="请选择" size="small">
                            <el-option v-for="(item, indexs) in options_equity" :key="indexs" :label="item.equity_id"
                                :value="item.id">
                                {{ item.name }} - {{ item.equity_id }}
                            </el-option>
                        </el-select>
                        <i class="el-icon-circle-plus " @click="addMemberEquity"></i>
                    </div>
                </el-form-item>
                <el-form-item label="是否支持购买">
                    <el-radio-group v-model="add_form.is_buy">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="卖价" v-show="add_form.is_buy == 1">
                    <el-input v-model="add_form.price" size="small" placeholder="price" style="width: 120px;">
                    </el-input>
                </el-form-item>
                <el-form-item label="是否固定等级" v-show="add_form.is_term == 0">
                    <div style="display: flex;align-items: center;">
                        <el-switch v-model="isFixed"></el-switch>
                        <span class="demand">选中保存后，此会员等级下的所有会员不回会因积分或余额的变化而升降级</span>
                    </div>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" size="small">取消</el-button>
                <el-button type="primary" @click="confirmAdd" size="small">保存</el-button>
            </span>
        </el-dialog>
        <!-- 其它升降级策略 123-->
        <el-dialog title="其它升降级策略" :visible.sync="dialogVisible_strategy" width="700px">
            <div class="dialog_top strategy">
                <div>
                    <p>间夜数指已预订人身份入住的间夜数,同住身份的间夜数无效,全天房算1个间夜,钟点房算0.5个间夜,特殊房算0间夜</p>
                </div>
            </div>
            <el-form ref="strategy_form" :model="strategy_form" label-width="80px">
                <el-form-item label="会员等级">
                    <span style="font-weight: 800;"> {{ strategy_form.title }}</span>
                </el-form-item>
                <el-form-item label="降级策略">
                    <div>
                        <el-radio :label="1" v-model="strategy_form.degrade.status">到期便降级 </el-radio>
                    </div>
                    <div>
                        <el-radio :label="2" v-model="strategy_form.degrade.status">
                            到期时,若期间内入住间夜数>=
                            <el-input size="mini" v-model="strategy_form.degrade.min_stay"
                                style="width: 60px;margin: 0 5px;"></el-input>保级
                            <div style="margin:5px 0 0  28px;">
                                到期时,若期间内入住间夜数 <span style="padding-right: 7px;">&lt </span>
                                <el-input size="mini" v-model="strategy_form.degrade.max_stay"
                                    style="width: 60px;margin: 0 5px;"></el-input>降级
                            </div>
                        </el-radio>
                    </div>
                </el-form-item>
                <el-form-item label="升级策略">
                    <div>
                        <div>1 按间夜升级</div>
                        <el-input size="small" v-model="strategy_form.upgradation.day_num.date" style="width:60px">
                        </el-input>
                        <el-select v-model="strategy_form.upgradation.day_num.unit" size="small" placeholder=""
                            style="width: 60px;margin-right: 5px;">
                            <el-option v-for="item in options_unit" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>内入住达到
                        <el-input size="small" v-model="strategy_form.upgradation.day_num.num"
                            style="width: 60px;margin: 0 5px;" placeholder=""></el-input>
                        间夜数时，在当日夜审后便升级
                    </div>
                    <div>
                        <div>2 按消费金额升级</div>
                        消费满 <el-input size="small" v-model="strategy_form.upgradation.consume.money"
                            style="width:80px;margin: 0 5px;"> </el-input>
                        升级
                    </div>
                </el-form-item>
            </el-form>
            <div class="dialog_top strategy">
                <div>
                    <p>注:升降级导致会员级别发生变更时，会员期限会自便更日起延后一个会员周期，同时定级间夜也会清空</p>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <div v-if="strategy_form_status == 'look'">
                    <el-button @click="strategy_form_status = 'edit'" size="small">修改</el-button>
                    <el-button type="primary" @click="dialogVisible_strategy = false" size="small">确定</el-button>
                </div>
                <div v-if="strategy_form_status == 'edit'">
                    <el-button @click="strategy_form_status = 'look'" size="small">取消</el-button>
                    <el-button type="primary" @click="confirm_strategy_form" size="small">保存</el-button>
                </div>
            </span>
        </el-dialog>

    </div>
</template>
<script>
import Pagination from '../../../../../components/PaginationPage/Pagination.vue';
export default {
    components: {
        Pagination
    },
    props: {
        type_id: {
            type: Number
        },
        activeName: {
            type: String
        }
    },
    watch: {
        activeName(newValue) {
            if (newValue == 'Grade') {
                this.getLevelList()
                this.getInfo()
                this.getFormLevel()
                this.getFormEquity()
            }
        }
    },
    data() {
        return {
            // 传递分页组件的数据
            pageNation_data: {
                total: 0,
                currentPage: 1,
                currentSize: 10,
                small: true
            },
            tableData_gradeList: [],//会员等级列表
            grade_detail: null,//会员等级详情
            options_equity: [],//权益列表
            dialogVisible: false,//新增会员等级对话框
            dialogVisible_strategy: false,//升降级策略对话框
            options_level: [],//会员级别
            options_unit: [
                {
                    value: 'y',
                    label: '年'
                },
                {
                    value: 'm',
                    label: '月'
                },
                {
                    value: 'd',
                    label: '日'
                }
            ],//有效期单位

            isFixed: false,//是否固定等级
            add_form_title: '新增会员等级',
            add_form: {
                is_term: 0,//是否期限会员 1 是 0不是
                title: '',//等级名称
                level_id: null,//级别id
                single_recharge: null,//单笔充值
                integral: null,//积分
                total_recharge: null,//累积充值
                is_buy: 1,//	是否支持购买 1支持 0不支持
                is_fixed: null,//是否固定等级 1是 0不是 is_term=0必传
                validty: null,//有效期 is_term =1 必传
                price: null,//售卖价格 is_buy=1 必传
                unit: null,//有效期单位 年：y 月：m 日：d,
                equity: ['']//权益id
            },
            strategy_form_status: 'look',// edit编辑  look查看  其它升降级策略对话框状态
            strategy_form: {
                id: null,
                title: null,
                degrade: {
                    status: null, // 1表示到期就降级  2表示按间夜数来降级
                    min_stay: null, // 保级的最小间夜数
                    max_stay: null, // 降级的最大间夜数
                },
                upgradation: {
                    day_num: {//间夜数
                        date: null, // 时间
                        unit: 'y', //  单位  年 y, 月 m ， 天 d
                        num: null, // 间夜数量
                    },
                    consume: {// 消费金额 
                        money: null, // money 金额  
                    },
                }
            },
            form: { //会员等级配置
                member_upgradation: null, // 会员等级升级规则设置  1 表示条件同时满足 2 满足任一条件即可
                member_degrade: { // 会员卡降级
                    status: null,  // 1开启  0 否 不开启
                    rule: {// 降级规则
                        money: null, // 余额
                        grade_id: null // 会员等级id
                    }
                },
                store_card: { // 储值卡
                    status: null, // 1开启 0 否 不开启
                    rule: { // 降级规则 
                        money: null // 余额
                    }
                },
                store_card_upgradation: null, // 储值卡升级策略  1按充值金额匹配等级 2 降级 恢复余额 即可恢复之前等级
                is_open_wx_view: null, // 是否开启公众号内查看权益 1开启 0不开启
                upgradation_show: null,// 会员升级展示条件  1 积分 2 累积充值 3 单笔充值
            },

        }
    },
    methods: {
        // 打开升降级策略对话框
        open_strategy_form(row) {
            console.log('查看的升降级策略的对话框信息', row)
            this.getGradeDetail(row.id)
            this.strategy_form.id = row.id
            this.strategy_form_status = 'look'
            this.dialogVisible_strategy = true
        },
        // 保存升降级策略 
        confirm_strategy_form() {
            console.log('保存升降级策略strategy_form', this.strategy_form)
            let data = {
                id: this.strategy_form.id,
                degrade: this.strategy_form.degrade,
                upgradation: this.strategy_form.upgradation,
            }
            console.log('保存升降级策略data', data)
            this.my_request('admin/member/grade/policy', data, 'PUT').then(res => {
                console.log('保存升降级策略结果', res)
                let { code, message } = res.data
                if (200 == code) {
                    this.$message.success('修改成功!')
                } else {
                    this.$message.error(message)
                }
            })
        },
        // 添加会员权益
        addMemberEquity() {
            this.add_form.equity.push('')
        },
        // 删除会员等级
        handleDelete(row) {
            let data = {
                id: row.id
            }
            this.my_request('admin/member/grade', data, 'DELETE').then(res => {
                console.log('删除会员等级结果', res)
                let { code, message } = res.data
                if (200 == code) {
                    this.$message.success('删除成功！')
                    this.getLevelList()
                } else {
                    this.$message.success(message)
                }
            })
        },
        // 修改会员等级
        handleEdit(row) {
            this.add_form_title = '编辑会员等级'
            this.getGradeDetail(row.id)
            this.dialogVisible = true
        },
        // 保存
        onSubmit() {
            let data = {
                id: this.type_id,
                member_upgradation: this.form.member_upgradation,
                member_degrade: this.form.member_degrade,
                store_card: this.form.store_card,
                store_card_upgradation: this.form.store_card_upgradation,
                is_open_wx_view: this.form.is_open_wx_view ? 1 : 0,
                upgradation_show: this.form.upgradation_show
            }
            console.log('submit data', data);
            this.my_request('admin/member/config/grade', data, 'PUT').then(res => {
                console.log('保存会员等级配置结果', res)
                let { code, message } = res.data
                if (200 == code) {
                    this.$message.success('保存成功')
                } else {
                    this.$message.error(message)
                }
            })
        },
        // 打开新增会员等级对话框
        openAddDialog() {
            this.add_form_title = '新增会员等级'
            this.add_form = {
                is_term: 0,//是否期限会员 1 是 0不是
                title: '',//等级名称
                level_id: null,//级别id
                single_recharge: null,//单笔充值
                integral: null,//积分
                total_recharge: null,//累积充值
                is_buy: 1,//	是否支持购买 1支持 0不支持
                is_fixed: 0,//是否固定等级 1是 0不是 is_term=0必传
                validty: null,//有效期 is_term =1 必传
                price: null,//售卖价格 is_buy=1 必传
                unit: null,//有效期单位 年：y 月：m 日：d,
                equity: ['']//权益id
            }
            this.isFixed = false
            this.dialogVisible = true
        },
        // 确认添加会员等级
        confirmAdd() {
            let data = {
                type_id: this.type_id,
                ...this.add_form
            }
            data.is_fixed = this.isFixed ? 1 : 0
            data.single_recharge = data.is_term == 0 ? data.single_recharge : 0
            data.integral = data.is_term == 0 ? data.integral : 0
            data.total_recharge = data.is_term == 0 ? data.total_recharge : 0
            data.validty = data.is_term == 1 ? data.validty : 0
            data.unit = data.is_term == 1 ? data.unit : 0
            data.price = data.is_buy == 1 ? data.price : 0
            data.equity = data.equity.join(',')
            data.id = this.add_form_title == '编辑会员等级' ? data.id : null

            let method = 'POST'
            let message_success = ''
            if (this.add_form_title == '编辑会员等级') {
                method = 'PUT'
                message_success = '修改成功'
                console.log('编辑会员等级')
            } else if (this.add_form_title == '新增会员等级') {
                method = 'POST'
                message_success = '添加成功'
                console.log('新增会员等级')
            }
            console.log('添加会员等级data', data)
            this.my_request('admin/member/grade', data, method).then(res => {
                console.log('编辑会员等级结果', res);
                let { code, message } = res.data
                if (200 == code) {
                    this.$message.success(message_success)
                    this.dialogVisible = false
                    this.getLevelList()
                } else {
                    this.$message.error(message)
                }
            })
        },

        // 分页组件事件
        handleSizeChange(val) {
            // 处理每页数量改变的逻辑
            console.log(`每页 ${val} 条`);
            this.pageNation_data.currentSize = val
            this.getLevelList()
        },
        handleCurrentChange(val) {
            // 处理当前页码改变的逻辑
            console.log(`当前页: ${val}`);
            this.pageNation_data.currentPage = val
            this.getLevelList()
        },

        // 获取会员级别
        getFormLevel() {
            this.my_request('admin/member/level').then(res => {
                // console.log('获取会员级别结果', res);
                let { code, data } = res.data
                if (200 == code) {
                    this.options_level = data
                }
            })
        },
        // 获取权益列表
        getFormEquity() {
            let data = {
                page: 1,
                page_rows: 1000,
            }
            this.my_request('/admin/member/equity', data, 'GET').then(res => {
                console.log('获取权益列表', res)
                let { code, data } = res.data
                if (200 == code) {
                    this.options_equity = data.list

                }
            })
        },
        // 获取会员等级详情
        getGradeDetail(id) {
            let data = {
                id,
            }
            this.my_request('admin/member/grade/show', data, 'GET').then(res => {
                console.log('获取会员等级详情结果', res.data)
                let { code, data } = res.data
                if (200 == code) {
                    // this.grade_detail = data
                    this.add_form = data
                    this.isFixed = this.add_form.is_fixed == 1 ? true : false
                    this.strategy_form.title = data.title
                    if (data.degrade.status) {
                        this.strategy_form.degrade = data.degrade
                        this.strategy_form.upgradation = data.upgradation
                    } else {
                        this.strategy_form.degrade = {
                            status: null, // 1表示到期就降级  2表示按间夜数来降级
                            min_stay: null, // 保级的最小间夜数
                            max_stay: null, // 降级的最大间夜数
                        }
                        this.strategy_form.upgradation = {
                            day_num: {//间夜数
                                date: null, // 时间
                                unit: 'y', //  单位  年 y, 月 m ， 天 d
                                num: null, // 间夜数量
                            },
                            consume: {// 消费金额 
                                money: null, // money 金额  
                            },
                        }
                    }

                    console.log(' this.strategy_form', this.strategy_form)
                }
            })
        },
        // 获取会员等级列表
        getLevelList() {
            let data = {
                type_id: this.type_id
            }
            this.my_request('admin/member/grade', data, 'GET').then(res => {
                console.log('获取会员等级列表结果', res);
                let { code, data } = res.data
                if (200 == code) {
                    this.tableData_gradeList = data.list
                    this.pageNation_data.total = data.total
                }
            })
        },
        // 获取会员详情
        getInfo() {
            let data = {
                type_id: this.type_id
            }
            // console.log('获取会员配置详情data:', data)
            this.my_request('admin/member/config', data, 'GET').then(res => {
                console.log('获取会员配置详情结果:', res)
                let { code, data } = res.data
                if (200 == code) {
                    let member_degrade = { // 会员卡降级
                        status: null,  // 1开启  0 否 不开启
                        rule: {// 降级规则
                            money: null, // 余额
                            grade_id: null // 会员等级id
                        }
                    }
                    let store_card = { // 储值卡
                        status: null, // 1开启 0 否 不开启
                        rule: { // 降级规则 
                            money: null // 余额
                        }
                    }
                    this.form.member_upgradation = data.member_upgradation
                    this.form.member_degrade = data.member_degrade.length == 0 ? member_degrade : data.member_degrade
                    this.form.store_card = data.store_card == 0 ? store_card : data.store_card
                    this.form.store_card_upgradation = data.store_card_upgradation
                    this.form.is_open_wx_view = data.is_open_wx_view == 1 ? true : false
                    this.form.upgradation_show = data.upgradation_show
                }
            })
        }
    },
    created() {
        this.getLevelList()
        this.getInfo()
        this.getFormLevel()
        this.getFormEquity()
    },
}
</script>
<style scoped lang="scss">
.dialog_top {
    display: flex;
    align-items: flex-start;
    line-height: 20px;
    justify-content: space-between;
    margin-bottom: 10px;
    color: #999999;

    div:nth-child(2) {
        width: calc(100% - 40px);

        p {
            margin: 0px !important;
        }
    }
}

.strategy {
    font-size: 12px;
    margin-top: -10px !important;
    margin-bottom: 0px !important;

    p {
        text-indent: 2em;
    }
}

::v-deep .el-dialog__wrapper {
    display: flex;
    align-items: center !important;
    justify-content: center;

    .el-dialog {
        margin-top: 0px !important;

        .el-dialog__body {
            padding: 10px 20px 0px 20px !important;
        }
    }
}

.addEquity {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .el-select {
        width: 120px;
        margin-right: 15px;
    }

    i {
        font-size: 20px
    }
}

.demand {
    color: #999999;
    font-size: 14px;
    margin-left: 20px;
}

.el-form-item {
    margin-bottom: 8px;
}

.gradeList_table {
    background-color: #61AFFF;
    height: 40px;
    width: 100%;

    .pagination {
        padding: 10px 0;
    }
}

.gradeList {
    width: 100%;
    height: 450px;

    .gradeList_top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

}


.line {
    background-color: white;
    border-top: 1px solid #dbdada;
    margin: 20px 0 20px 0;

    // background-color: #F2F2F2;
    // margin: 20px 0 20px -20px;
    height: 15px;
    width: calc(100% + 40px);
    position: relative;
    left: -20px;
}

.grade {
    width: 100%;

    // background-color: #61AFFF;
    .grade_top {
        font-size: 12px;
        background-color: #61AFFF;
        padding: 20px;
        color: white;

        div {
            margin-top: 10px;
            text-indent: 3em;
        }
    }

    .grade_form {
        margin-top: 10px;
    }
}
</style>