<template>
    <div>
        <div class="content getPoints">
            <div class="getPoints_top_item">
                <div class="getPoints_top_item_title"> 充值赠送积分 </div>
                <div>
                    <el-switch v-model="form.recharge_status"></el-switch>
                    <div style="padding: 15px 0;">
                        <!-- form.give -->
                        单笔充值每<el-input v-model="value" style="width:80px;margin: 0 5px;" size="mini" ></el-input>
                        元赠送<el-input v-model="form.recharge" style="width: 80px;margin: 0 5px;" size="mini"></el-input>积分
                        <span> 所填数额需>=1的整数</span>
                    </div>
                </div>
            </div>
            <!-- <div class="getPoints_top_item">
                <div class="getPoints_top_item_title"> 入住赠送积分 </div>
                <div>
                    <el-switch v-model="form.stay_status"></el-switch>
                    <span> 房费消费只包含房费，入住雄安飞包含房费和商品消费</span>
                </div>
            </div> -->
            <div class="getPoints_table"
                :style="{ 'height': maxHeight_TableContainer_getPoints + 'px', 'margin-top': '15px', }">
                <el-table :data="form.stay_rule" border style="width: 100%"
                    :max-height="maxHeight_TableContainer_getPoints">
                    <el-table-column prop="grade" label="会员等级" width="180" align="center"> </el-table-column>

                    <el-table-column align="center">
                        <template slot="header">
                            <span class="screen_title">
                                微信小程序
                            </span>
                        </template>
                        <template slot-scope="scope">
                            <div class="getPoints_table_item">
                                <!-- <el-checkbox-group v-model="value"> -->
                                <div>
                                    <!-- <el-checkbox v-model="value"> -->
                                    房费每消费
                                    <el-input v-model="scope.row.room_consume" style="width: 80px;margin: 0 5px;"
                                        size="mini"></el-input>
                                    元赠送
                                    <el-input v-model="scope.row.room_give" style="width: 80px;margin: 0 5px;"
                                        size="mini"></el-input>
                                    积分
                                    <!-- </el-checkbox> -->
                                </div>
                                <div>
                                    <!-- <el-checkbox v-model="value"> -->
                                    入住每消费
                                    <el-input v-model="scope.row.stay_consume" style="width:80px;margin: 0 5px;"
                                        size="mini"></el-input>
                                    元赠送
                                    <el-input v-model="scope.row.stay_give" style="width: 80px;margin: 0 5px;"
                                        size="mini"></el-input>
                                    积分
                                    <!-- </el-checkbox> -->
                                </div>
                                <!-- </el-checkbox-group> -->
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="getPoints_bottom">
                <el-button type="primary" size="small" @click="save">保存</el-button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        type_id: {
            type: Number
        },
        activeName: {
            type: String
        }
    },
    watch: {
        activeName(newValue) {
            if (newValue == 'GetPoints') {
                this.getInfo()
            }
        }
    },
    data() {
        return {
            maxHeight_TableContainer_getPoints: window.innerHeight - 366,
            tableData_getPoints: [],

            // 传递分页组件的数据
            pageNation_data: {
                total: 0,
                currentPage: 1,
                currentSize: 10,
                small: true
            },
            channel: [{
                id: 1,
                value: '微信小程序',
            }],
            num: 0,
            value: 1
            ,
            options: [],
            form: {
                stay_status: false,//入住状态 1开启 0关闭
                recharge_status: false,//入住状态 1开启 0关闭
                give: null,//充值赠送积分
                recharge: null,//充值金额
                stay_rule: [],//入住参数
            },
        }
    },
    created() {
        this.getInfo()
    },
    mounted() {
        // 监听窗口大小变化
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        // 移除窗口大小变化的监听
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        // 监听窗口大小变化
        handleResize() {
            // 在这里处理窗口大小变化的逻辑
            let screenHeight = window.innerHeight;
            this.maxHeight_TableContainer_getPoints = screenHeight - 360
        },
        // 保存
        save() {
            let data = {
                type_id: this.type_id,
                give: this.form.give,
                recharge: this.form.recharge,
                recharge_status: this.form.recharge_status ? 1 : 0,
                stay_status: this.form.stay_status ? 1 : 0,
                stay_rule: [],
            }
            this.form.stay_rule.forEach(item => {
                data.stay_rule.push({
                    id: item.id,
                    room_consume: item.room_consume,
                    room_give: item.room_give,
                    stay_consume: item.stay_consume,
                    stay_give: item.stay_give,
                })
            });

            console.log('保存积分详情data:', data)
            this.my_request('admin/member/type/integral', data, 'PUT').then(res => {
                console.log('保存积分详情结果:', res)
                let { code, data } = res.data
                if (200 == code) {
                    this.getInfo()
                    this.$message.success('保存成功')
                } else {
                    this.$message.error('保存失败')
                }
            })
        },
        getInfo() {
            let data = {
                type_id: this.type_id
            }
            // console.log('获取积分详情data:', data)
            this.my_request('admin/member/type/integral', data, 'GET').then(res => {
                console.log('获取积分详情结果:', res)
                let { code, data } = res.data
                if (200 == code) {
                    this.form = data
                    this.form.recharge_status = this.form.recharge_status == 0 ? false : true
                    this.form.stay_status = this.form.stay_status == 0 ? false : true
                    // this.form.stay_rule = [{
                    //     id: 1,
                    //     grade_id: 3,
                    //     stay_consume: "0.00",// 入住消费
                    //     stay_give: "0.00",// 入住消费赠送积分
                    //     room_consume: "0.00", // 房费消费
                    //     room_give: "0.00", // 房费赠送积分
                    //     grade: "超级会员" // 等级
                    // }]
                }
            })
        },

    }
}
</script>
<style scoped lang="scss">
.getPoints_table_item {
    div {
        margin-bottom: 10px;
    }

    div:last-child {
        margin-bottom: 0px !important;
    }
}

.getPoints {
    font-size: 14px;
    margin-top: 10px;

    .el-checkbox {
        margin-right: 0 !important;
    }

    .getPoints_bottom {
        height: 80px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .getPoints_top_item {
        display: flex;

        span {
            font-size: 11px;
            color: #999;
            margin-left: 10px;
        }

        .getPoints_top_item_title {
            line-height: 20px;
            margin-right: 20px;
        }

    }
}
</style>