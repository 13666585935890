<template>
    <div class="content RechargeGift">
        <div class="RechargeGift_top">
            <el-button type="primary" size="small" @click="openAdd">新增充值</el-button>
            <div>
                <el-select v-model="recharge_value" @change="getList" placeholder="请选择充值类型" style="width: 150px;"
                    size="small">
                    <el-option v-for="item in options_recharge" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <el-select v-model="level_value" @change="getList" placeholder="请选择会员等级"
                    style="width: 150px;margin: 0 10px;" size="small">
                    <el-option v-for="item in options_level" :key="item.id" :label="item.title" :value="item.id">
                    </el-option>
                </el-select>
                <el-button type="primary" size="small" @click="reset">重置</el-button>
            </div>
        </div>
        <div class="RechargeGift_table" :style="{ 'height': maxHeight_TableContainer_RechargeGift + 'px' }">
            <el-table :data="tableData_RechargeGift" :style="{ 'width': '100%', 'height': maxHeight_Table + 'px' }"
                :max-height="maxHeight_Table">
                <el-table-column prop="grade_text" label="通用会员"> </el-table-column>
                <el-table-column prop="recharge_type.text" label="充值类型"> </el-table-column>
                <el-table-column prop="effect_text" label="生效时段"> </el-table-column>
                <el-table-column label="充送规则">
                    <template slot-scope="scope">
                        <div v-for="(item, index) in scope.row.recharge_rule_text" :key="index">{{ item }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="120" align="right">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" @click="handleEdit(scope.row)">编辑</el-button>
                        <el-button size="mini" type="text" style="color: red;"
                            @click="handleDelete(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination_box">
                <Pagination :data="pageNation_data" @size-change="handleSizeChange" @current-change="handleCurrentChange">
                </Pagination>
            </div>
        </div>


        <el-dialog :title="title" :visible.sync="dialogFormVisible" width="700px">
            <el-form :model="form" label-width="80px">
                <el-form-item label="选择会员">
                    <el-radio-group v-model="form.grade_id">
                        <el-radio :label="item.id" v-for="item in options_level" :key="item.id">{{ item.title }}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="充值类型">
                    <el-radio-group v-model="form.recharge_type">
                        <el-radio :label="1">普通充值</el-radio>
                        <el-radio :label="2">会员日</el-radio>
                        <el-radio :label="3">会员首充</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="生效时段">
                    <div v-if="form.recharge_type == 1 || form.recharge_type == 3">
                        每 <el-select v-model="form.effect.rule.unit" placeholder="" style="width: 60px;" size="small">
                            <el-option v-for="item in options_unit" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <el-input v-model="form.effect.rule.date" placeholder="" size="small"
                            style="width: 80px;margin: 0 10px;"></el-input>
                    </div>
                    <div v-if="form.recharge_type == 2">
                        <div>
                            <el-radio v-model="form.effect.status" :label="1">无限制</el-radio>
                        </div>
                        <div>
                            <el-radio v-model="form.effect.status" :label="2"> 限制为</el-radio>
                            <!-- <el-input v-model="input" placeholder="" size="small" style="width: 100px;"></el-input>
                            <span style="margin: 0 15px;">至</span>
                            <el-input v-model="input" placeholder="" size="small" style="width: 100px;"></el-input> -->
                            <el-date-picker v-model="form.effect.rule.limit" size="mini" style="width: 300px;"
                                type="daterange" range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期"
                                end-placeholder="结束日期">
                            </el-date-picker>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="充值" class="formItem">
                    <div v-for="(item, index) in form.recharge_rule" :key="index"
                        :style="{ 'display': index == form.recharge_rule.length - 1 ? 'inline-block' : 'block' }">
                        <el-input v-model="item.money" placeholder="充值金额" size="small" style="width: 100px;"></el-input>
                        <span class="formItemSpan1">赠送</span>
                        <el-input v-model="item.give" placeholder="赠送金额" size="small" style="width: 100px;"></el-input>
                        <span class="formItemSpan2">元</span>
                    </div>
                    <el-button type="text" @click="addRecharge">添加</el-button>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false" size="small">取 消</el-button>
                <el-button type="primary" v-if="title == '新增充值赠送维护'" @click="confirm" size="small">确 定</el-button>
                <el-button type="primary" v-if="title == '编辑充值赠送维护'" @click="confirm_Edit" size="small">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import Pagination from '../../../../../components/PaginationPage/Pagination.vue';
export default {
    components: {
        Pagination
    },
    props: {
        type_id: {
            type: Number
        },
        activeName: {
            type: String
        }
    },
    watch: {
        activeName(newValue) {
            if (newValue == 'RechargeGift') {
                this.getList()
                this.getLevelList()
            }
        }
    },
    data() {
        return {
            title: '新增充值赠送维护',//对话框标题
            form: {
                grade_id: null,
                type_id: this.type_id,
                recharge_type: 1,
                effect: { // 生效规则
                    status: 1, // 1 表示为无限制 2 表示有限制
                    rule: {
                        unit: 'y',// 时间单位 年-> y , 月->m, 日 -> d
                        date: null, // 时间
                        limit: null// recharge_type = 2 必填
                    },

                },
                // 充值规则
                recharge_rule: [{
                    money: null,// 充值
                    give: null,// 赠送
                }],
            },
            Detail_Edit_id: null,//编辑会员详情id
            // 传递分页组件的数据
            pageNation_data: {
                total: 0,
                currentPage: 1,
                currentSize: 10,
                small: true
            },

            tableData_RechargeGift: [],//会员配置列表
            maxHeight_TableContainer_RechargeGift: window.innerHeight - 246,
            maxHeight_Table: window.innerHeight - 296,
            dialogFormVisible: false,//新增充值对话框
            options_recharge: [{
                value: '1',
                label: '普通充值'
            }, {
                value: '2',
                label: '会员日充值'
            }, {
                value: '3',
                label: '会员首充'
            }],
            options_unit: [
                {
                    value: 'y',
                    label: '年'
                },
                {
                    value: 'm',
                    label: '月'
                },
                {
                    value: 'd',
                    label: '日'
                }
            ],
            options_level: [],//会员级别
            recharge_value: null,//充值类型
            level_value: null,//会员级别
        }
    },
    mounted() {
        // 监听窗口大小变化
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        // 移除窗口大小变化的监听
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        // 打开新增充值对话框
        openAdd() {
            this.title = '新增充值赠送维护'
            this.form = {
                grade_id: this.options_level[0] && this.options_level[0].id,
                type_id: this.type_id,
                recharge_type: 1,
                effect: { // 生效规则
                    status: 1, // 1 表示为无限制 2 表示有限制
                    rule: {
                        unit: 'y',// 时间单位 年-> y , 月->m, 日 -> d
                        date: null, // 时间
                        limit: null// recharge_type = 2 必填
                    },

                },
                // 充值规则
                recharge_rule: [{
                    money: null,// 充值
                    give: null,// 赠送
                }],
            }
            this.dialogFormVisible = true
        },
        // 确认新增充值
        confirm() {
            let data = { ...this.form }
            data.effect.rule.limit = data.effect.rule.limit && data.effect.rule.limit.join(',')
            console.log('确认新增充值data', data);
            this.my_request('admin/member/config/recharge', data, 'POST').then(res => {
                console.log('添加配置结果', res)
                let { code, message } = res.data
                if (200 == code) {
                    this.$message.success('新增成功')
                    this.getList()
                    this.dialogFormVisible = false
                } else {
                    this.$message.error(message)
                }
            })
        },

        // 会员充值配置详情
        getDetail(id) {
            let data = {
                id,
            }
            console.log('会员充值配置详情data', data)
            this.my_request('admin/member/config/recharge/show', data, 'GET').then(res => {
                console.log('获取会员充值配置详情结果', res)
                let { code, data } = res.data
                if (200 == code) {
                    this.Detail_Edit_id = data.id
                    this.form.recharge_type = data.recharge_type.value
                    this.form.grade_id = Number(data.grade_id)
                    let dates = data.effect.rule.limit && data.effect.rule.limit.split(",")
                    this.form.effect = data.effect
                    this.form.effect.rule.limit = []
                    this.form.effect.rule.limit[0] = dates && dates[0]
                    this.form.effect.rule.limit[1] = dates && dates[1]
                    // eg,"充值100送20";
                    data.recharge_rule_text.forEach((item, index) => {
                        let money_fristIndex = item.indexOf('值') + 1
                        let money_lastIndex = item.indexOf('送')
                        let give_firstIndex = item.indexOf('送') + 1
                        let money = item.slice(money_fristIndex, money_lastIndex)
                        let give = item.slice(give_firstIndex)
                        this.form.recharge_rule[index] = { money, give }
                    })
                }
            })
        },
        // 编辑会员配置
        handleEdit(row) {
            console.log('编辑会员配置', row)

            this.title = '编辑充值赠送维护'
            this.getDetail(row.id)
            this.dialogFormVisible = true
        },
        confirm_Edit() {
            let data = {
                id: this.Detail_Edit_id,
                ...this.form
            }
            console.log('编辑data', data)
            this.my_request('admin/member/config/recharge', data, 'PUT').then(res => {
                console.log('编辑结果', res)
                let { code, message } = res.data
                if (200 == code) {
                    this.dialogFormVisible = false
                    this.$message.success('保存成功！')
                    this.getList()
                } else {
                    this.$message.error(message)
                }
            })
        },
        // 删除会员配置
        handleDelete(row) {
            let data = {
                id: row.id
            }
            console.log('删除会员配置row', row)
            this.my_request('admin/member/config/recharge', data, 'DELETE').then(res => {
                console.log('删除会员配置结果', res)
                let { code, message } = res.data
                if (200 == code) {
                    this.$message.success('删除成功！')
                    this.getList()
                } else {
                    this.$message.success(message)
                }
            })
        },
        // 分页组件事件
        handleSizeChange(val) {
            // 处理每页数量改变的逻辑
            console.log(`每页 ${val} 条`);
            this.pageNation_data.currentSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            // 处理当前页码改变的逻辑
            console.log(`当前页: ${val}`);
            this.pageNation_data.currentPage = val
            this.getList()
        },

        // 监听窗口大小变化
        handleResize() {
            // 在这里处理窗口大小变化的逻辑
            this.maxHeight_TableContainer_RechargeGift = window.innerHeight - 246
            this.maxHeight_Table = window.innerHeight - 296
        },
        // 添加充值赠送列表
        addRecharge() {
            this.form.recharge_rule.push({
                money: null, give: null
            })
        },

        // 获取会员等级列表
        getLevelList() {
            let data = {
                type_id: this.type_id
            }
            this.my_request('admin/member/grade', data, 'GET').then(res => {
                console.log('获取会员等级列表结果', res);
                let { code, data } = res.data
                if (200 == code) {
                    this.options_level = data.list
                    this.form.grade_id = this.options_level[0] && this.options_level[0].id
                }
            })
        },
        // 获取会员充值列表
        getList() {
            let data = {
                page: this.pageNation_data.currentPage,
                page_rows: this.pageNation_data.currentSize,
                grade_id: this.level_value,
                recharge_type: this.recharge_value,//充值类型 1普通充值 2会员日充值 3会员首冲
            }
            console.log('获取会员充值列表data:', data)
            this.my_request('admin/member/config/recharge', data, 'GET').then(res => {
                console.log('获取会员充值列表结果:', res)
                let { code, data } = res.data
                if (200 == code) {
                    this.tableData_RechargeGift = data.list
                    this.pageNation_data.total = data.total
                }
            })
        },
        reset() {
            this.level_value = null
            this.recharge_value = null
            this.getList()
        }
    },
    created() {
        this.getList()
        this.getLevelList()
    },
}
</script>
<style scoped lang="scss">
.formItem {

    .formItemSpan1 {
        margin: 0 15px;
    }

    .formItemSpan2 {
        margin: 0 30px 0 10px;
    }
}

.el-form-item {
    margin-bottom: 8px;
}

.RechargeGift {
    .RechargeGift_top {
        display: flex;
        justify-content: space-between;
        padding: 20px 0;
    }

    .RechargeGift_table {
        background-color: white;
    }

    .pagination_box {
        padding-top: 20px;
    }

}
</style>